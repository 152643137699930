import React, { useRef, useEffect } from 'react';
import Layout from '../components/layout';
import Container from 'emerald-ui/lib/Container';
import findElementNode from '../components/findElementNode';
import SEO from '../components/seo';

import ISF from '../images/about-isf.webp';
import cartagena from '../images/about-cartagena.webp';
import medellin from '../images/about-medellin.webp';
import jacksonville from '../images/about-jacksonville.webp';
import condorAwards from '../images/about-condor-awards.webp';
import logoWhite from '../images/logo-white.webp';
import damasco from '../images/about-damasco.webp';
import band from '../images/about-condor-band.webp';
import oneHundred from '../images/about-100.webp';
import condorTower from '../images/about-condor-tower.webp';
import towerMedellin from '../images/about-bio.webp';
import kuntur from '../images/about-kuntur.webp';
import wfh from '../images/about-wfh.webp';
import babyCondor from '../images/about-baby-condor.webp';
import colombia from '../images/about-colombia.webp';
import condor from '../images/about-condor.svg';
import productionTeam from '../images/about-production-team.webp';
import logosClCbEc from '../images/about-logos-cl-cb-ec.webp';
import newsLogos from '../images/about-new-logo-cl.webp';

import arrow1 from '../images/about-arrow-1.svg';
import arrow2 from '../images/about-arrow-2.svg';

import '../about.css';

function AboutPage() {
  const timelineNode = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: [0.5],
    };

    const animationObserver = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      }
    }, observerOptions);

    const articlesList = findElementNode(timelineNode).querySelectorAll('article[data-shown="false"]');

    articlesList.forEach((article) => animationObserver.observe(article));
  }, []);

  return (
    <div className="about-page">
      <Layout useDarkHeaderWithPaint>
        <SEO title="About us" />
        <h1>About us</h1>
        <Container fluid>
          <section className="timeline" ref={timelineNode}>
            <div className="row">
              <div className="hidden-xs"></div>
              <h2 className="center-line">
                <span>2001</span>
              </h2>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <article data-shown="false" className="isf left anim-1">
                <div className="content">
                  <div style={{ textAlign: 'right' }}>
                    <h3>ISF De Colombia</h3>
                    <span>We were born from ISF USA as ISF de Colombia.</span>
                  </div>
                  <img src={ISF} alt="" />
                </div>
                <div className="about-arrow">
                  <img src={arrow1} alt="" />
                </div>
              </article>
              <div className="center-line"></div>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <div className="center-line"></div>
              <article data-shown="false" className="cartagena right anim-2">
                <div className="about-arrow">
                  <img src={arrow2} alt="" />
                </div>
                <div className="content vertical">
                  <img src={cartagena} alt="" style={{ maxWidth: '279px' }} />
                  <h3>Cartagena</h3>
                  <span>We started our story in the beautiful city of Cartagena, next to the sea.</span>
                </div>
              </article>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <h2 className="center-line">
                <span>2016</span>
              </h2>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <article data-shown="false" className="medellin left anim-1">
                <div className="content vertical">
                  <img src={medellin} alt="" style={{ maxWidth: '143px' }} />
                  <h3>Medellín</h3>
                  <span>We expanded and opened a new office in Medellín.</span>
                </div>
                <div className="about-arrow">
                  <img src={arrow2} alt="" />
                </div>
              </article>
              <div className="center-line"></div>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <h2 className="center-line">
                <span>2017</span>
              </h2>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <div className="center-line"></div>
              <article data-shown="false" className="cl right anim-1">
                <div className="about-arrow">
                  <img src={arrow2} alt="" />
                </div>
                <div className="content vertical">
                  <img src={logoWhite} alt="" style={{ maxWidth: '198px' }} />
                  <h3>Condor Labs is born</h3>
                  <span>
                    We decided to make a stop along the way to reborn as an organization based on who we are, our
                    identity, and on what we want to become. We chose our name, for being the Condor an endemic bird,
                    unique like us, with an imposing personality and capable of flying very high.
                  </span>
                </div>
              </article>
            </div>
            <div className="row">
              <article data-shown="false" className="condor-awards left anim-1">
                <div className="content">
                  <img src={condorAwards} alt="" style={{ maxWidth: '102px' }} />
                  <div
                    style={{
                      maxWidth: '250px',
                      margin: '0 20px',
                    }}
                  >
                    <h3>Condor Awards</h3>
                    <span>
                      In order to recognize achievements, laugh at ourselves and have a big family time we created the
                      Condor Awards, a ceremony in which we give recognitions to outstanding Condorians in a series of
                      categories ranging from fun ones to admirable and serious ones.
                    </span>
                  </div>
                </div>
                <div className="about-arrow">
                  <img src={arrow1} alt="" />
                </div>
              </article>
              <div className="center-line"></div>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <div className="center-line"></div>
              <article data-shown="false" className="damasco right anim-2">
                <div className="about-arrow">
                  <img src={arrow2} alt="" />
                </div>
                <div className="content vertical">
                  <img src={damasco} alt="" style={{ maxWidth: '136px' }} />
                  <h3>Damasco Doctrine</h3>
                  <span>
                    We defined our own work and development methodology based on agile practices so we can deliver
                    quality products in a short time.
                  </span>
                </div>
              </article>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <h2 className="center-line">
                <span>2018</span>
              </h2>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <article data-shown="false" className="condor-band left anim-1">
                <div className="content vertical">
                  <img src={band} alt="" style={{ maxWidth: '196px' }} />
                  <div
                    style={{
                      textAlign: 'center',
                      maxWidth: '250px',
                      margin: '0 20px',
                    }}
                  >
                    <h3>Condor Band</h3>
                    <span>
                      Since several Condorians have artistic talents we decided to form a band to play in the Condor
                      Awards, as well as other important days such as women’s day and the Condor Labs Birthdays.
                    </span>
                  </div>
                </div>
                <div className="about-arrow">
                  <img src={arrow1} alt="" />
                </div>
              </article>
              <div className="center-line"></div>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <div className="center-line"></div>
              <article data-shown="false" className="condor-band right anim-2">
                <div className="about-arrow">
                  <img src={arrow1} alt="" />
                </div>
                <div className="content vertical">
                  <img src={productionTeam} alt="" style={{ maxWidth: '174px' }} />
                  <div
                    style={{
                      textAlign: 'center',
                      maxWidth: '250px',
                      margin: '0 20px',
                    }}
                  >
                    <h3>Production team</h3>
                    <span>
                      We created a specialized team that works on critical requests and bugs directly on the production
                      environment.
                    </span>
                  </div>
                </div>
              </article>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <h2 className="center-line">
                <span>2019</span>
              </h2>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <div className="center-line"></div>
              <article data-shown="false" className="jacksonville right anim-2">
                <div className="about-arrow">
                  <img src={arrow2} alt="" />
                </div>
                <div className="content vertical">
                  <img src={jacksonville} alt="" style={{ maxWidth: '292px' }} />
                  <h3>Jacksonville branch</h3>
                  <span>
                    We needed to expand to the USA to be closer to our American clients, so we opened a branch in
                    Jacksonville, Florida. That’s where our Production Team works.
                  </span>
                </div>
              </article>
            </div>
            <div className="row">
              <article data-shown="false" className="one-hundred left anim-1">
                <div className="content vertical">
                  <img src={oneHundred} alt="" style={{ maxWidth: '163px' }} />
                  <div
                    style={{
                      textAlign: 'center',
                      maxWidth: '300px',
                      margin: '0 20px',
                    }}
                  >
                    <h3>Member #100</h3>
                    <span>
                      After years of growing and expanding our offices we reached a very important milestone. We hired
                      our member number 100. That helped us to see how far we’d gone and to visualize the road ahead.
                    </span>
                  </div>
                </div>
                <div className="about-arrow">
                  <img src={arrow1} alt="" />
                </div>
              </article>
              <div className="center-line"></div>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <div className="center-line"></div>
              <article data-shown="false" className="condor-tower right anim-2">
                <div className="about-arrow">
                  <img src={arrow2} alt="" />
                </div>
                <div className="content vertical">
                  <img src={condorTower} alt="" style={{ maxWidth: '130px' }} />
                  <h3>Condor Tower Cartagena</h3>
                  <span>
                    We started growing a lot and we needed a new home so all the Condorians could work comfortably. In
                    July we moved to our new offices in the Condor Tower. Modern, spacious, beautiful offices. This is
                    the symbol of our rebirth.
                  </span>
                </div>
              </article>
            </div>
            <div className="row">
              <article data-shown="false" className="tower-medellin left anim-1">
                <div className="content vertical">
                  <img src={towerMedellin} alt="" style={{ maxWidth: '130px' }} />
                  <div
                    style={{
                      textAlign: 'center',
                      maxWidth: '350px',
                      margin: '0 20px',
                    }}
                  >
                    <h3>Condor Tower Medellín</h3>
                    <span>
                      Our Condorians in Medellín also got a new home in line with the new environment we designed. An
                      environment of collaboration, lateral thinking and having fun.
                    </span>
                  </div>
                </div>
                <div className="about-arrow">
                  <img src={arrow1} alt="" />
                </div>
              </article>
              <div className="center-line"></div>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <div className="center-line"></div>
              <article data-shown="false" className="kuntur right anim-2">
                <div className="about-arrow">
                  <img src={arrow2} alt="" />
                </div>
                <div className="content vertical">
                  <img src={kuntur} alt="" />
                  <h3>Kuntur is born</h3>
                  <span>
                    We created an illustration that represents our culture, our origins and our spirit. We call it
                    Kuntur and it&apos;s placed on all important graphical material we make.
                  </span>
                </div>
              </article>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <h2 className="center-line">
                <span>2020</span>
              </h2>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <article data-shown="false" className="wfh left anim-1">
                <div className="content vertical">
                  <img src={wfh} alt="" style={{ maxWidth: '161px' }} />
                  <div
                    style={{
                      textAlign: 'center',
                      maxWidth: '300px',
                      margin: '0 20px',
                    }}
                  >
                    <h3>Work from Home</h3>
                    <span>
                      When we had to leave our offices due to the COVID-19 pandemic, we started working from home and we
                      adapted extremely fast to this new way of working. We still have Condor Family Time activities, we
                      constantly talk with our coworkers and friends and our productivity has even increased!
                    </span>
                  </div>
                </div>
                <div className="about-arrow">
                  <img src={arrow1} alt="" />
                </div>
              </article>
              <div className="center-line"></div>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <div className="center-line"></div>
              <article data-shown="false" className="kuntur right anim-2">
                <div className="about-arrow">
                  <img src={arrow2} alt="" />
                </div>
                <div className="content vertical">
                  <img src={colombia} alt="" style={{ height: '250px' }} />
                  <h3>Condorians all over the country</h3>
                  <span>
                    We expanded to every city in Colombia. We&apos;re welcoming Condorians from every corner of our
                    country.
                  </span>
                </div>
              </article>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <h2 className="center-line">
                <span>2021</span>
              </h2>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <article data-shown="false" className="wfh left anim-1">
                <div className="content vertical">
                  <img src={babyCondor} alt="" style={{ height: '250px' }} />
                  <div
                    style={{
                      textAlign: 'center',
                      maxWidth: '300px',
                      margin: '0 20px',
                    }}
                  >
                    <h3>First condor born in captivity</h3>
                    <span>
                      The first condor is born in captivity in the National Aviary of Cartagena, and Condor Labs
                      sponsors it, contributing to the preservation of the species.
                    </span>
                  </div>
                </div>
                <div className="about-arrow">
                  <img src={arrow1} alt="" />
                </div>
              </article>
              <div className="center-line"></div>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <h2 className="center-line">
                <span>2022</span>
              </h2>
              <div className="hidden-xs"></div>
            </div>
            <div className="row">
              <div className="hidden-xs"></div>
              <div className="center-line"></div>
              <article data-shown="false" className="one-goal right anim-2">
                <div className="about-arrow">
                  <img src={arrow2} alt="" />
                </div>
                <div className="content vertical">
                  <img src={logosClCbEc} alt="" />
                  <h3>One Goal, One Community</h3>
                  <span>
                    Today after 5 years of achievements, learnings and challenges, we begin a new cycle together with CE
                    Broker and EverCheck, the two companies with whom we have worked hand in hand for a long time. Now
                    we are ONE COMMUNITY, with ONE GOAL.
                  </span>
                </div>
              </article>
            </div>
            <div className="row">
              <article data-shown="false" className="news-logos left anim-1">
                <div className="content vertical">
                  <img src={newsLogos} alt="" />
                  <div
                    style={{
                      textAlign: 'right',
                      maxWidth: '350px',
                      margin: '0 20px',
                    }}
                  >
                    <h3 style={{ textAlign: 'right' }}>New brand</h3>
                    <span>
                      Our logo evolves to align us more with the vision of a single community. We want everyone to know
                      the impact we have on society with our daily work and how technology is our best ally to make life
                      easier for those who make life easier for everyone. That’s why our slogan becomes: “Developing
                      delightful solutions for everyday heroes”
                    </span>
                  </div>
                </div>
                <div className="about-arrow">
                  <img src={arrow1} alt="" />
                </div>
              </article>
              <div className="center-line"></div>
              <div className="hidden-xs"></div>
            </div>
            <img className="condor" src={condor} alt="" />
          </section>
        </Container>
      </Layout>
    </div>
  );
}

export default AboutPage;
