function findElementNode(parentNode) {
  if (!parentNode || !parentNode.current) {
    return null;
  }

  if (parentNode.current instanceof Element) {
    return parentNode.current;
  }

  return findElementNode(parentNode.current.node);
}

export default findElementNode;
